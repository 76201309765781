import { Typography } from "antd";
import React from "react";
import Logo from "../assets/logo.png";
import { Firebase } from "./firebase";
import { Footer } from "./footer";
import { Form } from "./form";
import { Headline } from "./headline";
import { Recruitments } from "./recruitments";
import { ShareButton } from "./share-button";
import "./top.scss";
import { WorkStyleBaito } from "./work-style-baito";

const { Title } = Typography;

export function Top() {
  return (
    <>
      <header className="hero">
        <Title>
          <a href="https://togetter.com">
            <img src={Logo} />
          </a>
          <span>学生エンジニア採用サイト</span>
        </Title>
        <ShareButton />
      </header>
      <main>
        <img src="/header.jpg" />
        <section className="centered">
          <div className="centered-inner">
            <Headline />
            <Title className="centered-text" level={4}>
              業務内容
            </Title>
            <p className="centered-text">
              フロントエンド（React/TypeScript）・サーバサイド（PHP）・アプリ（React Native）・環境構築（Docker/CI）・新規サービス開発（言語問わず）
            </p>
            <Title className="centered-text" level={4}>
              代表からのメッセージ
            </Title>
            <div className="message">
              <img src="/ceo.jpg" />
              <p>
                プログラミングや開発が好きな方のご応募お待ちしております。Togetterも私が個人的に開発したサービスが元になっています！一緒に、新しいサービスやアプリを生み出して行きましょう！
              </p>
            </div>
          </div>
        </section>
        <Form />
        <section className="centered">
          <div className="centered-inner">
            <Title className="centered-text" level={4}>
              社内での使用言語・技術
            </Title>
            <p className="centered-text">
              PHP / JavaScript / TypeScript / React / React Native / AWS /
              nginx / GCP / MySQL / Redis / Firebase / Elasticsearch / MeCab /
              NEologd / webpack / etc.
            </p>
            <WorkStyleBaito />
          </div>
        </section>
        <Recruitments />
        <Firebase />
      </main>
      <Footer />
    </>
  );
}
