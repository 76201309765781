import { Typography } from "antd";
import React from "react";

const { Title } = Typography;

export function FukuriKousei() {
  return (
    <>
      <Title className="centered-text" level={4}>
        福利厚生
      </Title>
      <p className="centered-text">
        開発用マシン支給、健康保険（IT健保）、交通費全額支給、書籍購入費補助、セミナー参加費、渡航費、無料シネマチケット
        <br />
        （正社員・アルバイトのみ対象）
      </p>
    </>
  );
}
