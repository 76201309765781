import { Typography } from "antd";
import React from "react";
import { Stepper } from "./stepper";

const { Title } = Typography;

export function Form() {
  return (
    <>
      <section className="centered">
        <div className="centered-inner">
          <Title className="centered-text" level={4}>
            外部サービス連携で応募！
          </Title>
          <p className="centered-text">
            TwitterやGitHubと連携するだけで応募は完了します。連携されたアカウントから普段の活動を拝見し、面談等をさせていただくか判断いたします。
          </p>
          <div className="stepper-container">
            <Stepper />
          </div>
          <p className="centered-text">
            面談等に進む場合は、5営業日以内にメールアドレスにご連絡いたします。
            <br />
            備考欄のプロフィールなどを充実させていただけるとよりスムーズです。
            <br />
            最長でも残り２ステップ（オンライン面談等）で判断させていただきます。
          </p>
        </div>
      </section>
    </>
  );
}
