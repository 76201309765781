import { Typography } from "antd";
import React from "react";

const { Title } = Typography;

export function WorkPlace() {
  return (
    <>
      <Title className="centered-text" level={4}>
        勤務地
      </Title>
      <p className="centered-text">東京・フルリモート</p>
      <p className="centered-text">
        フルリモートで勤務している社員・アルバイトも多数在籍しており、地方在住でも問題ありません。
        <br />
        SlackやGoogle
        Hangoutsを導入しているため、フルリモートの方でも円滑なコミュニケーションが可能です。
      </p>
    </>
  );
}
