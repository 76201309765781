import { Button, Form, Result, Spin, Steps } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Text from "antd/lib/typography/Text";
import {
  getAuth,
  GithubAuthProvider,
  GoogleAuthProvider,
  linkWithPopup,
  TwitterAuthProvider,
  User,
} from "firebase/auth";
import {
  addDoc,
  collection,
  DocumentReference,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import React, { SFC, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { ApplicationStatus, Remark } from "../model";
import { cancel, fetchGitHubLoginName, fetchTwitterScreenName, notify } from "./api";
import { useFS } from "./firebase/hooks";
import "./stepper.scss";
import { ERROR_CODE_CREDENTIAL_ALREADY_IN_USE } from "./util";

const { Step } = Steps;

export function Stepper() {
  const db = getFirestore();
  const auth = getAuth();

  const [user, userLoading, userError] = useAuthState(auth);
  const userId = user ? user.uid : null;
  const [currentStep, setCurrentStep] = useState<Step>(0);
  const [twitterScreenName, setTwitterScreenName] = useState<string | null>(
    null,
  );
  const [githubLoginName, setGitHubLoginName] = useState<string | null>(null);
  const {
    remarkDoc,
    remarkDocLoading,
    setUserId,
    applicationDoc,
    applicationDocLoading,
  } = useFS(userId);
  const [valueFromTextArea, setvalueFromTextArea] = useState<string | null>(
    null,
  );
  const [deleting, setDeleting] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const applicationDocData = applicationDoc
    ? (applicationDoc.data() as ApplicationStatus)
    : undefined;

  const remarkDocData = remarkDoc ? (remarkDoc.data() as Remark) : undefined;

  const onFormSubmit = async () => {
    setSubmitting(true);
    const remark = valueFromTextArea;

    const p1: Promise<void | DocumentReference> = applicationDoc
      ? updateDoc(applicationDoc.ref, {
          userId,
          completed: true,
          at: Date.now(),
        } as ApplicationStatus)
      : addDoc(applicationCollection, {
          userId,
          completed: true,
          at: Date.now(),
        } as ApplicationStatus);

    const p2: typeof p1 = remark ? (remarkDoc
      ? updateDoc(remarkDoc.ref, { remark } as Remark)
      : addDoc(remarkCollection, {
        userId,
        remark,
      } as Remark)) : Promise.resolve();
    await Promise.all([p1, p2]).catch(
      () => alert(
        "備考情報の更新に失敗しました。申し訳ありませんが、もう一度お試しください。",
      ),
    );

    if (!user) {
      return;
    }
    await user.getIdToken().then((idToken: any) => notify(idToken));
    setSubmitting(false);
  };

  const getStatusText = (
    identityProvider: "twitter.com" | "github.com",
  ): string => {
    const screenName =
      identityProvider === "twitter.com" ? twitterScreenName : githubLoginName;
    const defaultMsg =
      identityProvider === "twitter.com"
        ? "普段のツイートを拝見します。勝手なツイートやフォローはいたしません。"
        : "公開リポジトリを確認する目的にのみ使われます。それ以外の情報を閲覧することはありません。";
    if (!user) {
      return defaultMsg;
    }
    const displayName = pickDisplayName(user, identityProvider);

    return displayName && screenName
      ? `${displayName}（@${screenName}）`
      : displayName && !screenName
      ? displayName
      : screenName && !displayName
      ? `@${screenName}`
      : defaultMsg;
  };

  useEffect(() => {
    setUserId(userId);
  }, [userId]);

  useEffect(() => {
    (async () => {
      if (user) {
        const screenName = await fetchTwitterScreenName(user.uid).catch(
          () => null,
        );
        setTwitterScreenName(screenName);
        const loginName = await fetchGitHubLoginName(user.uid).catch(
          () => null,
        );
        setGitHubLoginName(loginName);
      } else {
        setTwitterScreenName(null);
        setGitHubLoginName(null);
      }
    })();
  }, [userId, currentStep]);

  useEffect(() => {
    if (user) {
      const linkedWith1 = linkedWith(user);
      if (linkedWith1("twitter.com")) {
        moveOn(1);
        if (linkedWith1("github.com")) {
          moveOn(2);
          if (linkedWith1("google.com")) {
            moveOn(3);
            if (!submitting && applicationDocData && applicationDocData.completed) {
              moveOn(4);
            }
          }
        }
      }
    } else {
      moveOn(0);
    }
  }, [user, applicationDocData, submitting]);

  const moveOn = (step: Step) => setCurrentStep(step);

  const remarkCollection = collection(db, "remarks");

  const applicationCollection = collection(db, "applications");

  return (
    <div className="stepper">
      {((userLoading && (applicationDocLoading || remarkDocLoading)) ||
        deleting) && (
        <div className="spin-container">
          <Spin />
        </div>
      )}
      {userError && (
        <Result
          status="error"
          title="ユーザ情報の取得に失敗しました"
          subTitle={userError.message}
        />
      )}
      {!userLoading && !userError && !deleting && (
        <>
          <Steps direction="vertical" current={currentStep}>
            <Step
              title="Twitter連携してはじめる"
              description={getStatusText("twitter.com")}
            />
            <Step
              title="GitHubアカウントと接続する"
              description={getStatusText("github.com")}
            />
            <Step
              title="Googleアカウントと接続する"
              description={
                user
                  ? pickGmailAddress(user) ||
                    "連絡用のメールアドレスを取得します。メールアドレス以外の情報は利用いたしません。"
                  : "連絡用のメールアドレスを取得します。メールアドレス以外の情報は利用いたしません。"
              }
            />
            <Step
              title="備考を入力（任意）"
              description={
                remarkDocData
                  ? remarkDocData.remark
                  : "ブログやQiitaのアカウント、これまでに公開・制作したもの等があれば教えてください。"
              }
            />
            <Step
              title="応募完了"
              description={
                currentStep === 4
                  ? "ご応募ありがとうございます。採用の方には、数日中にメールでご連絡致します。"
                  : undefined
              }
            />
          </Steps>
          <div className="step-contents">
            {currentStep === 0 || !user ? (
              <div>
                <StyledFirebaseAuth
                  className="firebaseui"
                  uiConfig={{
                    signInFlow: "popup",
                    // useAuthState より user が更新されるので、move(1) は不要
                    // また、ここで move(1) すると、ステップ2（GitHub連携）が完了しているアカウントが Twitter ログインを行った際、
                    // user が更新され move(2〜4) された後にログイン後のコールバックが入り、ステップ2が完了しているにもかかわらず
                    // GitHub の連携表示になってしまう。
                    signInOptions: [
                      TwitterAuthProvider.PROVIDER_ID,
                    ],
                  }}
                  firebaseAuth={auth}
                />
              </div>
            ) : currentStep === 1 ? (
              <div>
                <LinkWith
                  user={user}
                  linkedCallback={() => moveOn(2)}
                  providerId={"github.com"}
                />
              </div>
            ) : currentStep === 2 ? (
              <div>
                <LinkWith
                  user={user}
                  linkedCallback={() => moveOn(3)}
                  providerId={"google.com"}
                />
              </div>
            ) : currentStep === 3 ? (
              <Form className="remark-form" onFinish={onFormSubmit}>
                <Form.Item>
                  <TextArea
                    defaultValue={
                      remarkDocData && remarkDocData.remark.length > 0
                        ? remarkDocData.remark
                        : undefined
                    }
                    placeholder="例）ポートフォリオやブログへのURL"
                    onChange={(e) => setvalueFromTextArea(e.target.value)}
                    rows={4}
                  />
                </Form.Item>
                <Form.Item>
                  {submitting
                    ? <div className="spin-container">
                        <Spin />
                      </div>
                    : <Button className="fw" type="primary" htmlType="submit">
                        応募を完了する
                      </Button>
                  }
                </Form.Item>
              </Form>
            ) : (
              <></>
            )}
          </div>
          {user && (
            <div>
              <Button
                className="fw margin-top"
                disabled={submitting}
                onClick={async () => {
                  const ok = confirm(
                    "応募はキャンセルされ、外部サービス連携も解除されます。よろしいですか？",
                  );
                  if (!ok) {
                    return;
                  }
                  setDeleting(true);
                  const idToken = await user.getIdToken();
                  const deleted = await cancel(idToken);

                  if (deleted) {
                    alert("応募はキャンセルされました。");
                    await auth.signOut();
                  } else {
                    alert(
                      "応募ステータスの削除に失敗しました。申し訳ありませんが、もう一度お試しください。",
                    );
                  }
                  setDeleting(false);
                }}
                danger={true}
              >
                応募をキャンセル
              </Button>
              <div className="centered-text">
                <Text type="danger">※入力された情報はすべて削除されます。</Text>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

interface ILinkButtonProps {
  providerId: "google.com" | "github.com";
  user: User;
  linkedCallback: () => void;
}

const LinkWith: SFC<ILinkButtonProps> = (props) => {
  const { providerId, linkedCallback, user } = props;
  const providerName = providerId === "github.com" ? "GitHub" : "Google";
  const googleSvg =
    "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg";
  const githubSvg =
    "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/github.svg";
  const linkWith1 = linkWith(user);
  return providerName === "Google" ? (
    <Button
      className="idp-google firebaseui-idp-button mdl-button mdl-js-button
      mdl-button--raised firebaseui-idp-google firebaseui-id-idp-button"
      onClick={() => linkWith1(providerId).then((ok) => ok && linkedCallback())}
    >
      <Text className="firebaseui-idp-icon-wrapper">
        <img className="firebaseui-idp-icon" src={googleSvg} />
      </Text>
      <Text className="firebaseui-idp-text firebaseui-idp-text-long">
        Link with {providerName}
      </Text>
    </Button>
  ) : (
    <Button
      className="idp-github firebaseui-idp-button mdl-button mdl-js-button
      mdl-button--raised firebaseui-idp-github firebaseui-id-idp-button"
      onClick={() => linkWith1(providerId).then((ok) => ok && linkedCallback())}
    >
      <Text className="firebaseui-idp-icon-wrapper">
        <img className="firebaseui-idp-icon" src={githubSvg} />
      </Text>
      <Text className="firebaseui-idp-text firebaseui-idp-text-long">
        Link with {providerName}
      </Text>
    </Button>
  );
};

function linkedWith(user: User) {
  return (providerId: string) =>
    Boolean(user.providerData.find((d) => d && d.providerId === providerId));
}

function linkWith(user: User) {
  return (providerId: string) => {
    const authProvider =
      providerId === "github.com"
        ? new GithubAuthProvider()
        : providerId === "google.com"
        ? new GoogleAuthProvider()
        : null;
    if (!authProvider) {
      throw new Error(`providerId: ${providerId} は無効です`);
    }

    return linkWithPopup(user, authProvider)
      .then(() => true)
      .catch((e: ILinkWithPopupError) => {
        if (e.code === ERROR_CODE_CREDENTIAL_ALREADY_IN_USE) {
          alert(
            "このアカウントを利用してサインインしたことがあるか、既に他のアカウントに紐付いています。",
          );
        } else {
          alert(
            "不明なエラーが発生しました。申し訳ありませんが、もう一度お試しください。",
          );
          console.error(e);
        }
        return false;
      });
  };
}

interface ILinkWithPopupError {
  message: string;
  code: string;
}

type Step = 0 | 1 | 2 | 3 | 4;

function pickDisplayName(
  user: User,
  providerId: "github.com" | "twitter.com",
): string | null {
  const { providerData } = user;
  const found = providerData.find((pd) => pd && pd.providerId === providerId);
  return found && found.displayName ? found.displayName : null;
}

function pickGmailAddress(user: User): string | null {
  const { providerData } = user;
  const found = providerData.find((pd) => pd && pd.providerId === "google.com");
  return found && found.displayName ? found.email : null;
}
