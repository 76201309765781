import { collection, DocumentSnapshot, getFirestore, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";

export function useFS(initialUserId: string | null) {
  const db = getFirestore();

  // initialize our default state
  const [remarkDocError, setRemarkDocError] = useState<Error | null>(null);
  const [remarkDocLoading, setRemarkDocLoading] = useState(true);
  const [
    remarkDoc,
    setRemark,
  ] = useState<DocumentSnapshot | null>(null);
  const [applicationDocError, setApplicationDocError] = useState<Error | null>(
    null,
  );
  const [applicationDocLoading, setApplicationDocLoading] = useState(true);
  const [
    applicationDoc,
    setApplicationDoc,
  ] = useState<DocumentSnapshot | null>(null);
  const [userId, setUserId] = useState<string | null>(initialUserId);
  // when the id attribute changes (including mount)
  // subscribe to the recipe document and update
  // our state when it changes.
  useEffect(() => {
    if (!userId) {
      return;
    }
    (async () => {
      const unsubscribeRemarks = onSnapshot(query(collection(db, "remarks"), where("userId", "==", userId)), {
        next: (remarkCollection) => {
          setRemarkDocLoading(false);
          if (remarkCollection.docs.length > 1) {
            console.error(
              `エラー: ユーザ ${userId} の備考情報が複数見つかりました`,
            );
            return;
          }
          setRemark(remarkCollection.docs[0]);
        },
        error: (err) => {
          setRemarkDocError(err);
        },
      });

      const unsubscribeApplications = onSnapshot(query(collection(db, "applications"), where("userId", "==", userId)), {
        next: (applicationCollection) => {
            setApplicationDocLoading(false);
            if (applicationCollection.docs.length > 1) {
              console.error(
                `エラー: ユーザ ${userId} の備考情報が複数見つかりました`,
              );
              return;
            }
            setApplicationDoc(applicationCollection.docs[0]);
          },
        error: (err) => {
          setApplicationDocError(err);
        },
      });

      // returning the unsubscribe function will ensure that
      // we unsubscribe from document changes when our id
      // changes to a different value.
      return () => {
        unsubscribeRemarks();
        unsubscribeApplications();
      };
    })();
  }, [userId]);

  return {
    remarkDoc,
    remarkDocLoading,
    remarkDocError,
    applicationDoc,
    applicationDocLoading,
    applicationDocError,
    setUserId,
  };
}
