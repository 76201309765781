import { Typography } from "antd";
import React from "react";

const { Title } = Typography;

export function Recruitments() {
  return (
    <>
      <section className="centered">
        <div className="centered-inner">
          <Title className="centered-text" level={4}>
            その他の採用
          </Title>
          <p className="centered-text">
            <a href="https://bosyu.togetter.dev/">サーバサイド（PHP）</a>
            {"/ "}
            <a href="https://designer-bosyu.togetter.dev/"> デザイナー</a>
            {" / "}
            <a href="https://rn-bosyu.togetter.dev/">アプリ（React Native）</a>
            {"/ "}
            <a href="https://baito-bosyu.togetter.dev/">学生エンジニア</a>
          </p>
        </div>
      </section>
    </>
  );
}
