// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js!../node_modules/normalize.css/normalize.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js!../node_modules/antd/dist/antd.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{font-family:-apple-system,BlinkMacSystemFont,Helvetica Neue,Segoe UI,Hiragino Kaku Gothic ProN,Hiragino Sans,ヒラギノ角ゴ ProN W3,Arial,メイリオ,Meiryo,sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;-webkit-font-feature-settings:\"kern\";font-feature-settings:\"kern\";-webkit-font-kerning:normal;font-kerning:normal;background-color:#7fc6bb}", "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AACQ,KAER,wJACI,CAAA,kCACA,CAAA,iCACA,CAAA,oCACA,CAAA,4BACA,CAAA,2BACA,CAAA,mBACA,CAAA,wBACA","sourcesContent":["@import \"~normalize.css/normalize.css\";\n@import '~antd/dist/antd.css';\n\nbody {\n    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Segoe UI,Hiragino Kaku Gothic ProN,Hiragino Sans,ヒラギノ角ゴ ProN W3,Arial,メイリオ,Meiryo,sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    -webkit-font-feature-settings: \"kern\";\n    font-feature-settings: \"kern\";\n    -webkit-font-kerning: normal;\n    font-kerning: normal;\n    background-color: #7FC6BB;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
