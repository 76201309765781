import React from "react";

export function Footer() {
  return (
    <>
      <footer>
        <section className="centered">
          <div className="centered-inner">
            <p className="centered-text">
              <a href="https://togetter.com/">Togetter</a>
              {" / "}
              <a href="https://ch.togetter.com/">トゥギャッチ</a>
              {" / "}
              <a href="https://min.togetter.com/">min.t</a>
            </p>
            <p className="centered-text">
              その他の採用情報は、
              <a href="https://www.wantedly.com/companies/togetter">Wantedly</a>
              へ
            </p>
            <p className="centered-text">
              収集した情報は、採用の目的にのみ利用し、一定期間後すべて削除いたします。
            </p>
            <p className="centered-text">
              Copyright 2020-2022 togetter.com. All Rights Reserved.
            </p>
          </div>
        </section>
      </footer>
    </>
  );
}
