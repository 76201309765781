import React from "react";

export function ShareButton() {
  return (
    <>
      <div className="share-button">
        <a
          href="https://b.hatena.ne.jp/entry/"
          className="hatena-bookmark-button"
          data-hatena-bookmark-layout="basic-label"
          data-hatena-bookmark-lang="ja"
          title="このエントリーをはてなブックマークに追加"
        >
          <img
            src="https://b.st-hatena.com/images/v4/public/entry-button/button-only@2x.png"
            alt="このエントリーをはてなブックマークに追加"
            width="20"
            height="20"
          />
        </a>
        <a
          href="https://twitter.com/share?ref_src=twsrc%5Etfw"
          className="twitter-share-button"
          data-show-count="false"
        >
          Tweet
        </a>
        <div
          className="fb-like"
          data-width=""
          data-layout="button_count"
          data-action="like"
          data-size="small"
          data-show-faces="false"
          data-share="true"
        />
      </div>
    </>
  );
}
