import { Typography } from "antd";
import React from "react";
import { FukuriKousei } from "./fukuri-kousei";
import { WorkPlace } from "./work-place";

const { Title } = Typography;

export function WorkStyle() {
  return (
    <>
      <Title className="centered-text" level={4}>
        雇用形態
      </Title>
      <p className="centered-text">
        正社員・業務委託（フリーランス・副業）・アルバイト・インターン
        <br />
        フルリモート可
      </p>
      <WorkPlace />
      <Title className="centered-text" level={4}>
        勤務時間
      </Title>
      <p className="centered-text">
        正社員 : 11時出社、昼休憩1時間、20時退社（調整可）
        <br />
        業務委託・アルバイト : 自由、週10時間以上推奨
      </p>
      <Title className="centered-text" level={4}>
        報酬
      </Title>
      <p className="centered-text">
        正社員 : 30万円/月 ~
        <br />
        業務委託 : 2000円/時 ~（換算）
        <br />
        アルバイト : 1100円 ~ 3000円/時 （応相談）
      </p>
      <p className="centered-text">
        成果に応じて随時昇給（年に複数回）
        <br />
        ※自己申告による昇給制度あり
      </p>
      <FukuriKousei />
    </>
  );
}
