// import "firebase/auth";
import React from "react";
import { render } from "react-dom";
import "./index.scss";

import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Admin } from "./admin";
import { config } from "./firebase/config";
import { Top as baito } from "./top-baito-recruitment";
import { Top as design } from "./top-design-recruitment";
import { Top as dev } from "./top-dev-recruitment";
import { Top as rn } from "./top-rn-recruitment";

initializeApp(config);

try {
  if (process.env.FIRESTORE_EMULATOR_ENABLED === "true"
    && typeof process.env.FIRESTORE_EMULATOR_HOST === "string"
    && typeof process.env.FIRESTORE_EMULATOR_PORT === "string"
  ) {
    connectFirestoreEmulator(
      getFirestore(),
      process.env.FIRESTORE_EMULATOR_HOST,
      parseInt(process.env.FIRESTORE_EMULATOR_PORT, 10),
    );
  }
  if (process.env.FIREBASE_AUTH_EMULATOR_ENABLED === "true"
    && typeof process.env.FIREBASE_AUTH_EMULATOR_URL === "string"
) {
    connectAuthEmulator(getAuth(), process.env.FIREBASE_AUTH_EMULATOR_URL);
  }
} catch (e) {
  if (!(e instanceof ReferenceError)) {
    throw e;
  }
}

type Templates = { [K in string]: () => JSX.Element };

const templates: Templates = {
  dev,
  baito,
  design,
  rn,
};

const TopTemplate = templates[process.env.TEMPLATE || "baito"];

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<TopTemplate />} />
        <Route path="/admin" element={<Admin />} />
      </Routes>
    </BrowserRouter>
  );
}

render(<App />, document.getElementById("root"));
