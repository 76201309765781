import axios from "axios";

import { IUserRecord } from "../model";

export function fetchUsers(option?: {
  accessToken: string;
  idToken: string;
}): Promise<IUserRecord[]> {
  return axios
    .get(
      `${process.env.API_ROOT}/appliers${
        option
          ? `?ghAccessToken=${option.accessToken}&idToken=${option.idToken}`
          : ""
      }`,
      { withCredentials: true },
    )
    .then((res) => res.data);
}

export function fetchTwitterScreenName(uid: string): Promise<string> {
  return axios
    .get(`${process.env.API_ROOT}/twitter_screen_name?uid=${uid}`)
    .then((res) => res.data);
}

export function fetchGitHubLoginName(uid: string): Promise<string> {
  return axios
    .get(`${process.env.API_ROOT}/github_login_name?uid=${uid}`)
    .then((res) => res.data);
}

export function notify(idToken: string): Promise<boolean> {
  return axios
    .post(
      `${process.env.API_ROOT}/applications?idToken=${idToken}&template=${process.env.TEMPLATE}`,
    )
    .then((res) => (res.data === "ok" ? true : false));
}

export function cancel(idToken: string): Promise<boolean> {
  return axios
    .post(
      `${process.env.API_ROOT}/cancel?idToken=${idToken}`,
    )
    .then((res) => (res.status === 204 ? true : false));
}
