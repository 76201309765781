import { Typography } from "antd";
import React from "react";
import Logo from "../assets/logo.png";
import { Firebase } from "./firebase";
import { Footer } from "./footer";
import { Form } from "./form";
import { Headline } from "./headline";
import { Recruitments } from "./recruitments";
import { ShareButton } from "./share-button";
import "./top.scss";
import { WorkStyleEmployee } from "./work-style-employee";

const { Title } = Typography;

export function Top() {
  return (
    <>
      <header className="hero">
        <Title>
          <a href="https://togetter.com">
            <img src={Logo} />
          </a>
          <span>PHPエンジニア採用サイト</span>
        </Title>
        <ShareButton />
      </header>
      <main>
        <img src="/header.jpg" />
        <section className="centered">
          <div className="centered-inner">
            <Headline />
            <Title className="centered-text" level={4}>
              業務内容
            </Title>
            <p className="centered-text">
              サーバサイド（PHP）/ 開発環境の整備（Docker/CI）/ 機能の仕様策定 / 新規サービスの開発（開発言語問わず）
            </p>
            <Title className="centered-text" level={4}>
              代表からのメッセージ
            </Title>
            <div className="message">
              <img src="/ceo.jpg" />
              <p>
                トゥギャッターはPHP8を採用しており、独自の設計によりレスポンスタイムに拘った開発をしています。新規の開発案件が山積みですので新しいメンバーの参加をお待ちしております。
              </p>
            </div>
          </div>
        </section>
        <Form />
        <section className="centered">
          <div className="centered-inner">
            <Title className="centered-text" level={4}>
              社内での使用言語・技術
            </Title>
            <p className="centered-text">
              PHP / ドメイン駆動、DDD / JavaScript / TypeScript / React /
              React Native / AWS / nginx / GCP / MySQL / Redis / Firebase /
              Elasticsearch / MeCab / NEologd / webpack / etc.
            </p>
            <WorkStyleEmployee />
          </div>
        </section>
        <Recruitments />
        <Firebase />
      </main>
      <Footer />
    </>
  );
}
