export const config = {
  apiKey: "AIzaSyD1J08FilYxI7a4PBfd-X-kMTvMcCz-7CY",
  authDomain: "dev-recruitment.firebaseapp.com",
  databaseURL: "https://dev-recruitment.firebaseio.com",
  projectId: "dev-recruitment",
  storageBucket: "",
  messagingSenderId: "299507071219",
  appId: "1:299507071219:web:c0f9ec606fdb1a21",
};

export const adminConfig = {
  apiKey: "AIzaSyCd1qudQuGGr9KwnG8wEH0s2pwiAZQvlZU",
  authDomain: "dev-recruitment-admin.firebaseapp.com",
  databaseURL: "https://dev-recruitment-admin.firebaseio.com",
  projectId: "dev-recruitment-admin",
  storageBucket: "",
  messagingSenderId: "861491542402",
  appId: "1:861491542402:web:d5a7de631f24dba4",
};
