import { Typography } from "antd";
import React from "react";
import Logo from "../assets/logo.png";
import { Firebase } from "./firebase";
import { Footer } from "./footer";
import { Form } from "./form";
import { Recruitments } from "./recruitments";
import { ShareButton } from "./share-button";
import "./top.scss";
import { WorkStyle } from "./work-style";

const { Title } = Typography;

export function Top() {
  return (
    <>
      <header className="hero">
        <Title>
          <a href="https://togetter.com">
            <img src={Logo} />
          </a>
          <span>デザイナー（正社員/副業/アルバイト）採用サイト</span>
        </Title>
        <ShareButton />
      </header>
      <main>
        <img src="/header.jpg" />
        <section className="centered">
          <div className="centered-inner">
            <Title className="centered-text" level={2}>
              業界最短！今すぐ応募！
              <br />
              新しいデザイナー採用
            </Title>
            <p className="centered-text">
              正社員・業務委託・副業・フルリモートOK！
              <br />
              応募は1分で完了！
            </p>
            <Title className="centered-text" level={4}>
              応募条件
            </Title>
            <p className="centered-text">
              年齢、学歴は問いません。どなたでも応募可能です。また、実務経験も必要ありません。これまでの制作物や経験、アウトプットを重要視させていただきます。
            </p>
            <Title className="centered-text" level={4}>
              業務内容
            </Title>
            <div className="centered-text">
              <ul>
                <li>
                  UI/UX担当
                  <br />
                  Webサービスやアプリケーションの
                  <br />
                  UI/UXデザインや仕様策定業務
                </li>
                <li>
                  デザイン担当
                  <br />
                  特集やキャンペーンサイトなどの
                  <br />
                  ページデザイン、イラスト制作
                </li>
              </ul>
            </div>
            <Title className="centered-text" level={4}>
              代表からのメッセージ
            </Title>
            <div className="message">
              <img src="/ceo.jpg" />
              <p>
                毎月2000万人が見ているサービスを一緒に成長させてくれるメンバーを探しています！Webやアプリ、更には周辺サービスなどお願いしたい仕事がたくさんあります。
              </p>
            </div>
          </div>
        </section>
        <Form />
        <section className="centered">
          <div className="centered-inner">
            <Title className="centered-text" level={4}>
              社内での使用ツール
            </Title>
            <p className="centered-text">
              Adobe Photoshop / Illustrator / XD / Premiere など
              <br />
              Adobe Creative Cloud を採用
              <br />
              その他、必要な環境は無制限に準備いたします。
            </p>
            <WorkStyle />
          </div>
        </section>
        <Recruitments />
        <Firebase />
      </main>
      <Footer />
    </>
  );
}
