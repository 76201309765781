import React from "react";
import Logo from "../assets/logo-built_white.png";

export function Firebase() {
  return (
    <>
      <section className="centered">
        <div className="centered-inner">
          <img className="firebase" src={Logo} />
          <p className="centered-text">
            Hosting / Functions / Firestore / Authentication
          </p>
        </div>
      </section>
    </>
  );
}
