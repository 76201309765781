import { format } from "date-fns";
import * as ja from "date-fns/locale/ja";

export const ERROR_CODE_CREDENTIAL_ALREADY_IN_USE =
  "auth/credential-already-in-use";

export function mapObjectToReadableString(o: object): string {
  return JSON.stringify(o, null, 2);
}

export function msToNiceJPDateTimeString(ms: number) {
  return format(new Date(ms), "YYYY/MM/DD HH:mm", {
    locale: ja,
  });
}

export function isNotFalsy<T>(value: T): value is NonNullable<T> {
  return value !== undefined && value !== null;
}
