import { Typography } from "antd";
import React from "react";
import { FukuriKousei } from "./fukuri-kousei";
import { WorkPlace } from "./work-place";

const { Title } = Typography;

export function WorkStyleBaito() {
  return (
    <>
      <Title className="centered-text" level={4}>
        雇用形態
      </Title>
      <p className="centered-text">
        アルバイト・インターン
        <br />
        フルリモート可
      </p>
      <WorkPlace />
      <Title className="centered-text" level={4}>
        勤務時間
      </Title>
      <p className="centered-text">自由、週10時間以上推奨</p>
      <Title className="centered-text" level={4}>
        報酬
      </Title>
      <p className="centered-text">1100円 ~ 3000円/時（応相談）</p>
      <p className="centered-text">
        成果に応じて随時昇給（年に複数回）
        <br />
        ※自己申告による昇給制度あり
      </p>
      <FukuriKousei />
    </>
  );
}
