import { Typography } from "antd";
import React from "react";

const { Title } = Typography;

export function Headline() {
  return (
    <>
      <Title className="centered-text" level={2}>
        業界最短！今すぐ応募！
        <br />
        新しいエンジニア採用
      </Title>
      <p className="centered-text">
        正社員・業務委託・副業・フルリモートOK！
        <br />
        応募は1分で完了！
      </p>
      <Title className="centered-text" level={4}>
        応募条件
      </Title>
      <p className="centered-text">
        年齢、学歴は問いません。どなたでも応募可能です。また、実務経験も必要ありません。これまでの開発経験や、アウトプットを重要視させていただきます。
      </p>
    </>
  );
}
