import { GithubFilled, MailFilled, TwitterCircleFilled } from "@ant-design/icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faGithub,
  faGoogle,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Avatar, Card, Empty, Result, Spin, Typography } from "antd";
import Meta from "antd/lib/card/Meta";
import { AxiosError } from "axios";
import { initializeApp } from "firebase/app";
import { getAuth, GithubAuthProvider, User, UserCredential } from "firebase/auth";
import * as firebaseui from "firebaseui";
import React, { useEffect, useState } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { IUserRecord } from "../model";
import "./admin.scss";
import { fetchUsers } from "./api";
import { adminConfig } from "./firebase/config";
import { msToNiceJPDateTimeString } from "./util";

const adminApp = initializeApp(adminConfig, "admin");

library.add(faTwitter, faGithub, faGoogle, faEnvelope);

const { Title } = Typography;

function isNotFalsy<T>(value: T): value is NonNullable<T> {
  return value !== undefined && value !== null;
}

function mapProviderDataToIcons(user: IUserRecord): React.ReactNode[] {
  const { twitterUrl, githubUrl, gmail } = user;
  const twitterIcon = twitterUrl ? (
    <a target="_blank" href={twitterUrl}>
      <TwitterCircleFilled key={twitterUrl}/>
    </a>
  ) : null;
  const githubIcon = githubUrl ? (
    <a target="_blank" href={githubUrl}>
      <GithubFilled key={githubUrl} />
    </a>
  ) : null;
  const mailIcon = gmail ? (
    <a href={`mailto:${gmail}`}>
      <MailFilled key={gmail} />
    </a>
  ) : null;
  return [twitterIcon, githubIcon, mailIcon].filter(isNotFalsy);
}

export function Admin() {
  const [auth, setAuth] = useState<{
    ghAccessToken: string | null;
    user: User | null;
  }>({ ghAccessToken: null, user: null });

  const [users, setUsers] = useState<IUserRecord[] | null>(null);

  const [loadingUser, setLoadingUser] = useState(false);

  const [fetchUserError, setFetchUserError] = useState<string | null>(null);
  // sessionが有効であれば、認証なしにユーザを取得できる
  useEffect(() => {
    (async () => {
      setLoadingUser(true);
      fetchUsers()
        .then(setUsers)
        .catch(() => console.log("Session not found."))
        .finally(() => setLoadingUser(false));
    })();
  }, []);

  useEffect(() => {
    const { ghAccessToken, user } = auth;
    if (ghAccessToken && user) {
      (async () => {
        const idToken = await user.getIdToken(true).catch((e) => {
          console.error(e);
          return null;
        });
        if (!idToken) {
          return;
        }

        setLoadingUser(true);
        fetchUsers({ accessToken: ghAccessToken, idToken })
          .then(setUsers)
          .catch((error: AxiosError<string>) =>
            setFetchUserError(
              error.response
                ? error.response.data
                : "このページの閲覧権限がありません",
            ),
          )
          .finally(() => setLoadingUser(false));
      })();
    }
  }, [auth]);

  // https://github.com/firebase/firebaseui-web-react/issues/59#issuecomment-1152975312
  useEffect(() => {
    const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(getAuth(adminApp));
    const oldDelete = ui.delete;
    ui.delete = async () => {};
    return () => {
        ui.delete = oldDelete;
    };
  });

  return (
    <div className="admin-container">
      {loadingUser ? (
        <div className="spin-container">
          <Spin />
        </div>
      ) : users ? (
        <section className="appliers">
          <Title>応募者一覧</Title>
          <div className="container">
            {fetchUserError ? (
              <Result
                status="error"
                title="ユーザ取得エラー"
                subTitle="データベースからユーザ一覧を取得できませんでした。"
              />
            ) : users === null ? (
              <div className="spin-container">
                <Spin />
              </div>
            ) : users.length === 0 ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
              users.map((user) => (
                <Card
                  className="user"
                  key={user.uid}
                  actions={mapProviderDataToIcons(user)}
                >
                  <Meta
                    avatar={user.photoURL && <Avatar src={user.photoURL} />}
                    title={user.displayName && user.displayName}
                    description={
                      user.applicationCompletedAt &&
                      msToNiceJPDateTimeString(user.applicationCompletedAt)
                    }
                  />
                  {user.remark && <p className="remark">{user.remark}</p>}
                </Card>
              ))
            )}
          </div>
        </section>
      ) : fetchUserError ? (
        <Result
          status="error"
          title="ユーザ一覧の取得に失敗しました"
          subTitle={fetchUserError}
        />
      ) : (
        <section className="section centered">
          <div>
            <p>このページの閲覧にはサインインが必要です。</p>
            <StyledFirebaseAuth
              className="firebaseui"
              uiConfig={{
                signInFlow: "popup",
                callbacks: {
                  signInSuccessWithAuthResult: (
                    authResult: UserCredential & {
                      credential: { accessToken?: string };
                    },
                  ) => {
                    const { credential } = authResult;
                    if (credential && credential.accessToken) {
                      setAuth({
                        user: authResult.user,
                        ghAccessToken: credential.accessToken,
                      });
                    }
                    return false;
                  },
                },
                signInOptions: [
                  {
                    provider: GithubAuthProvider.PROVIDER_ID,
                    defaultCountry: "JP",
                    scopes: ["read:org"],
                  },
                ],
              }}
              firebaseAuth={getAuth(adminApp)}
            />
          </div>
        </section>
      )}
    </div>
  );
}
